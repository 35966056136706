import * as React from "react";
import "./index.css";
import AccueilPage from "./accueil";


const IndexPage = () => {
  return (
    <>
      <AccueilPage />
    </>
  )
}

export default IndexPage

export const Head = () => <title>Medica-Tour: chirurgie esthétique en Tunisie, prix séjour esthétique</title>
